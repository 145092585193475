import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";

import bloggerspace from "../../Assets/Projects/bloggerspace.jpeg";
import brainquiz from "../../Assets/Projects/brainquiz.jpeg";
import mydiary from "../../Assets/Projects/mydiary.jpeg";
import sei from "../../Assets/Projects/sei.jpeg";
import zerocross from "../../Assets/Projects/zerocross.jpeg";
import youchat from "../../Assets/Projects/youchat.jpeg";
import StarsCanvas from "../Stars";
import { Link } from "react-router-dom";
import {
  FaArrowRight,
  FaArrowLeft,
} from "react-icons/fa";


function Projects() {
  return (
    <Container fluid className="project-section">
      <StarsCanvas />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bloggerspace}
              title="BloggerSpace"
              description="A blogging website where users can write a blog on any topic. There are two panels: Writing and Reviewing panel. In writing panel, anyone can signup and start writing blogs."
              ghLink="https://github.com/singhteekam/BloggerSpace"
              demoLink="https://bloggerspace.singhteekam.in/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={brainquiz}
              title="BrainQuiz"
              description="Brain quiz is an online game that has cool graphics and amazing sound effects. It lets you challenge yourself and your friends. It has interesting questions from different categories according to your interest. "
              ghLink="https://github.com/singhteekam/Kaun-Banega-Crorepati"
              demoLink="https://brainquiz.singhteekam.in/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mydiary}
              title="MyDiary"
              description="My diary app is an easy and safe online diary app to store your memories in one place. With this diary app, you can write your feelings or memories. You can search your notes anytime you want. "
              ghLink="https://github.com/singhteekam/MyDiary"
              demoLink="https://mydiary.singhteekam.in/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={zerocross}
              title="ZeroCross Game"
              description="The Zero Cross game is a free and classic puzzle game also known as Noughts and Crosses (0 and X). It is played on a 3x3 grid. It is a two-player game, the player who can mark the row in horizontal, vertical, or diagonal manner wins the game."
              ghLink="https://github.com/singhteekam/Tic-Tac-Toe"
              demoLink="https://zerocrossgame.singhteekam.in/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sei}
              title="SEI Official"
              description="This is chatting app made with Flutter and firebase."
              ghLink="https://github.com/singhteekam/"
              demoLink="#"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={youchat}
              title="YouChat"
              description="The Zero Cross game is a free and classic puzzle game also known as Noughts and Crosses (0 and X). It is played on a 3x3 grid. It is a two-player game, the player who can mark the row in horizontal, vertical, or diagonal manner wins the game."
              ghLink="https://github.com/singhteekam/Tic-Tac-Toe"
              demoLink="https://zerocrossgame.singhteekam.in/"
            />
          </Col>
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bloggerspace}
              title="BloggerSpace"
              description="A blogging website where users can write a blog on any topic. There are two panels: Writing and Reviewing panel. In writing panel, anyone can signup and start writing blogs."
              ghLink="https://github.com/singhteekam/BloggerSpace"
              demoLink="https://bloggerspace.singhteekam.in/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bloggerspace}
              title="BloggerSpace"
              description="A blogging website where users can write a blog on any topic. There are two panels: Writing and Reviewing panel. In writing panel, anyone can signup and start writing blogs."
              ghLink="https://github.com/singhteekam/BloggerSpace"
              demoLink="https://bloggerspace.singhteekam.in/"
            />
          </Col> */}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Bits-0f-C0de"
              description="My personal blog page build with Next.js and Tailwind Css which takes the content from makdown files and renders it using Next.js. Supports dark mode and easy to write blogs using markdown."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col> */}

        </Row>

        <div className="prev-next">
          <Link className="btn btn-outline-success prevnext-btn" to="/about">
            <FaArrowLeft /> About Me
          </Link>

          <Link className="btn btn-outline-success prevnext-btn" to="/experience">
            Experience <FaArrowRight />
          </Link>
        </div>
      </Container>
    </Container>
  );
}

export default Projects;
