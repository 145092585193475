import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import myImg from "../../Assets/boy.webp";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import {
  FaLinkedinIn,
  FaArrowRight,
  FaArrowLeft,
  FaLocationArrow,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

function Section2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I’m Teekam Singh currently working in Tata Consultancy
              Services(TCS) having 2+ year of Experience.
              <br />
              <br />
              I'm skilled in
              <i>
                <b className="purple">
                  {" "}
                  React.js, Express.js, Node.js and MongoDB.{" "}
                </b>
              </i>
              <br />
              <br />I have developed &nbsp;
              <i>
                <b className="purple">BloggerSpace website </b> for Bloggers{" "}
                <b className="purple">to write blogs on any topic</b>
              </i>
              <br />
              <br />I have created many projects using{" "}
              <b className="purple">Flutter</b> and
              <i>
                <b className="purple"> Firebase</b>
              </i>
              &nbsp; in my college time like
              <i>
                <b className="purple"> BrainQuiz, MyDiary etc</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid myimg-avatar" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>
              <b className="purple">CONNECT ME</b> ON
            </h1>
            {/* <p>
              Feel free to <span className="purple">connect </span>with me
            </p> */}
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="mailto:singhteekam.in@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <MdEmail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/singhteekam"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/singhteekam/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/singh__teekam"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        <div className="prev-next">
          <Link
            className="btn btn-outline-success prevnext-btn"
            target="_blank"
            to="https://www.singhteekam.in/"
          >
            <FaArrowLeft /> Old Portfolio{" "}
          </Link>

          <Link className="btn btn-outline-success prevnext-btn" to="/about">
            About <FaArrowRight />
          </Link>
        </div>
      </Container>
    </Container>
  );
}
export default Section2;
